import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {useNavigate} from 'react-router-dom'

const EmployeeDash = (props) =>{
const navigate = useNavigate()
const [name,setName] = useState('')
const [team,setTeam] = useState('')
const [id,setId] = useState('')
const [total,setTotal] = useState('')
const [teamName,setTeamName] = useState('')
// const [total,setTotal] = useState('')
  

  async function logout(){
    localStorage.removeItem("loggedIn")
    localStorage.removeItem("jwt")
    props.setLoggedIn(false)
    navigate('/')
  }

  async function getEmployeeData(){
    let token = localStorage.getItem('jwt')
    try{
      let res = await fetch('https://tempestapi.onrender.com/employee-data',{
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      if(res.ok){
        let data = await res.json()
        console.log(data)
        setName(data.name)
        setId(data.employeeId)
        setTotal(data.total)
        setTeamName(data.teamName)
        let teamMates = data.team.map(element => {
                
          return <div class="bg-blue-round">{element}</div>
        });
        setTeam(teamMates)
      }
    }catch(e){
      console.log(e)
    }
  }

  useEffect(()=>{
    getEmployeeData()
  },[])

    return(
        
    <div class="relative z-index-9999">
    <div class="gradient-hover-section bg-grey">
      <div class="gradient-1"></div>
    </div>
    <div class="bg-grey">
      <div data-w-id="0de833cd-285f-f43d-8d63-139cfe9b77a9" class="section">
       
        <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
          <div class="container">
            <a href="#" id="first_element" class="brand nav-btn w-nav-brand"><img src="https://uploads-ssl.webflow.com/6421d264d066fd2b24b91b20/6421d264d066fd6711b91c04_tempest_logo_spiral_with_coin.png" loading="eager" alt="" class="logo-icon-2"/><img src="https://uploads-ssl.webflow.com/6421d264d066fd2b24b91b20/6421d264d066fdff16b91b5b_tempest-logo-name.svg" loading="lazy" alt="" class="logo-name-2"/></a>
            <nav role="navigation" class="nav-menu w-nav-menu">
            <NavLink   to={'/'}>
              <div href="#" class="nav-link w-inline-block">
                <div class="nav-text-1">Dashboard</div>
                <div class="nav-text-2">Dashboard</div>
              </div>
            </NavLink>
            </nav>
            <div class="account-menu-container">
              <div class="parent-navbar">
                <div class="flex-buttons">
                <button onClick={logout} href="#" className="account-link w-inline-block">
                    Sign Out
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid two-landscape">
          <div id="w-node-_48ede02d-434e-e4ba-b266-d67abf3f7953-a3474485" class="box vertical more-space">
            <div class="h3 center">Your token sales</div>
            <div class="form-block _15-margin-top w-form">
            </div>
            <div class="light-blue-container _15-margin-top"><img src="images/coin-small_1coin-small.png" loading="lazy" alt="" class="coin-icon"/>
              <div class="h3">{total}</div>
              <div class="h3">TMPST Tokens</div>
            </div>
          </div>
          <div id="w-node-edc57a36-f968-e616-6f0d-8fcc8380ec2f-a3474485" class="box vertical more-space">
            <div class="h3 center">Welcome, {name}!</div>
            <div class="referral-code-div">
              <div class="bg-blue-round">My referral code:</div>
              <div class="referral">{`https://contribute.tmpst.io/${id}`}</div>
            </div>
          </div>
          <div id="w-node-_843f81a9-06ab-b136-14d0-4ab540c3a055-a3474485" class="box left more-space">
            <div class="h3 center">Quick links &amp; resources</div>
            <div class="links-container">
              <a href="#" class="grey-link w-inline-block">
                <div>Change my password</div>
                <div class="icon-2 _5-margin-left w-embed"><svg width="18" height="9" viewbox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.3536 4.85355C17.5488 4.65829 17.5488 4.34171 17.3536 4.14645L14.1716 0.964466C13.9763 0.769204 13.6597 0.769204 13.4645 0.964466C13.2692 1.15973 13.2692 1.47631 13.4645 1.67157L16.2929 4.5L13.4645 7.32843C13.2692 7.52369 13.2692 7.84027 13.4645 8.03553C13.6597 8.2308 13.9763 8.2308 14.1716 8.03553L17.3536 4.85355ZM0 5H17V4H0V5Z" fill="black" fill-opacity="0.5"></path>
                  </svg></div>
              </a>
             
            </div>
          </div>
          <div id="w-node-_876e1fed-50af-5c47-f412-458bf4ea8a92-a3474485" class="box left more-space">
            <div class="h3 center">My team</div>
            <div class="team-name green"><strong>&quot;{teamName}&quot;</strong></div>
            <div class="divider-line-3 no-space"></div>
            <div class="links-container no-space">{
              team
            }
            </div>
          </div>
        </div>
    
      </div>
    </div>
  </div>
        
    )

}

export default EmployeeDash