import React from 'react'
import {useState,useEffect} from 'react'
import {Spin} from 'antd'
import { NavLink } from "react-router-dom";
import {useNavigate} from 'react-router-dom'

function Dash(props) {
  const [time,setTime] = useState('')
  const [user,setUser] = useState('')
  const [Data,setData] = useState('')
  const [spin,setSpin] = useState(false)
  const [teams,setTeams] = useState()
  const [leader,setLeader] = useState()
  const [totalUsd,setTotalUsd] = useState(0)
  const [totaltokens,setTotaltokens] = useState(0)
  const navigate = useNavigate()
  
  async function logout(){
    localStorage.removeItem("loggedIn")
    localStorage.removeItem("jwt")
    props.setLoggedIn(false)
    navigate('/')
  }

  async function getTotal(){
    const res = await fetch('https://tempestapi.onrender.com/total',
    {
      method:'GET',
      headers:{'Content-Type':'application/json'},
      
    })

    if(res.ok){
      let data = await res.json()
      setTotalUsd(data.total.toFixed(2))
      setTotaltokens(data.total/0.15)
      console.log(data.total)
    }
  }


  async function getData(e){
    
    if(e){
      e.preventDefault()
    }

    
    const body = {user:user, time:time}
    try {
      setSpin(true)
      const res = await fetch('https://tempestapi.onrender.com/data',
      {
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify(body)
      })
  
      if(res.ok){
        let data = await res.json()
        data = data.map(charge=>{
          return(
            <div className="data-grid _12-pt">
            <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a690033a-a6913dbd">
              <div id="person-email" className="w-node-_454c95e2-b67c-98c2-4171-43e0a690033b-a6913dbd">{charge.email}</div>
            </div>
            <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a690033d-a6913dbd" className="grey">
              <div id="date-time-of-purchase" className="w-node-_454c95e2-b67c-98c2-4171-43e0a690033e-a6913dbd">{charge.time}</div>
            </div>
            <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900340-a6913dbd" className="grey">
              <div id="payment-method" className="w-node-_454c95e2-b67c-98c2-4171-43e0a6900341-a6913dbd">{charge.source}</div>
            </div>
            <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900343-a6913dbd" className="grey">
              <div id="usdc-of-purchase" className="green w-node-_454c95e2-b67c-98c2-4171-43e0a6900344-a6913dbd">{charge.amount}</div>
            </div>
            <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900346-a6913dbd" className="grey">
              <div id="tmpst-token-bought" className="green w-node-_454c95e2-b67c-98c2-4171-43e0a6900347-a6913dbd">$TMPST</div>
            </div>
          </div>
          )
        })
        setSpin(false)
        return setData(data)
      }
      
    } catch (error) {
      setSpin(false)
      console.log(error)
    }

  }

  async function getTeams(){

    let res = await fetch('https://tempestapi.onrender.com/teams')
    let data = await res.json()
    console.log(data)
    let teams = data?.map(team=>{
      return <div id="w-node-_69a6d588-15a2-d7cf-26b4-dcf84a8134f7-a6913dbd" class="box vertical top">
      <div class="flex-wide-2 top">
        <div class="h2 bg-blue">{team.teamName}</div>
        <div class="tokens-amount">
          <div class="token-container">
            <div id="tmpst-token-bought" class="blue-w-bg">{team.total/0.25}</div>
            <div id="tmpst-token-bought" class="sm">tokens</div>
          </div>
          <div id="tmpst-token-bought" class="green-w-bg">${team.total}</div>
        </div>
      </div>
      <div class="divider-line small"></div>
      {team.employeeTotal.map(employee=>{
        return <div class="flex-wide-2">
        <div id="person-email">{employee.name}</div>
        <div class="tokens-amount">
          <div class="token-container">
            <div id="tmpst-token-bought" class="blue-w-bg">{employee.totalSales/0.25}</div>
            <div id="tmpst-token-bought" class="sm">tokens</div>
          </div>
          <div id="tmpst-token-bought" class="amont-total">${employee.totalSales }</div>
        </div>
      </div>
      })}
    
    </div>
    })

    let lead = 
      <div id="w-node-e5c1514f-cac6-2f5f-fa50-94961daf4528-a6913dbd" class="box left more-space">
            <div class="h3 center"><strong>Leaderboard</strong></div>
            <div class="divider-line no-space"></div>
            <div class="links-container no-space">
              {
              data.sort((a,b)=> b.total - a.total).map(te=>{
                return<div class="flex-wide-2 _7-margin-top">
                <div id="person-email">{te.teamName}</div>
                <div class="tokens-amount">
                  <div class="token-container">
                    <div id="tmpst-token-bought" class="blue-w-bg">{te.total/0.25}</div>
                    <div id="tmpst-token-bought" class="sm">tokens</div>
                  </div>
                  <div id="tmpst-token-bought" class="amont-total">${te.total}</div>
                </div>
              </div>
              })
              }
            </div>
          </div>
    

     setTeams(teams)
     setLeader(lead)

  }


  useEffect(()=>{
    getData()
    getTotal()
    getTeams()
    
  },[])


  return (
    <div className="relative z-index-9999">
    <div className="gradient-hover-section bg-grey">
      <div className="gradient-1"></div>
    </div> 
    <div className="bg-grey">
      <div data-w-id="454c95e2-b67c-98c2-4171-43e0a69002df" className="section">
        <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
          <div className="container w-container">
            <a href="#" id="first_element" className="brand nav-btn w-nav-brand"><img src="https://uploads-ssl.webflow.com/6421d264d066fd2b24b91b20/6421d264d066fd6711b91c04_tempest_logo_spiral_with_coin.png" loading="eager" alt="" className="logo-icon-2"/><img src="https://uploads-ssl.webflow.com/6421d264d066fd2b24b91b20/6421d264d066fdff16b91b5b_tempest-logo-name.svg" loading="lazy" alt="" className="logo-name-2"/></a>
            <nav role="navigation" class="nav-menu w-nav-menu">
              <NavLink to={'/employee-dash'}>
              <div href="#" class="nav-link w-inline-block">
                <div class="nav-text-1">Profile</div>
                <div class="nav-text-2">Profile</div>
              </div>
              </NavLink>
            </nav>
            <div className="account-menu-container">
              <div className="parent-navbar">
                <div className="flex-buttons">
                  <button onClick={logout} href="#" className="account-link w-inline-block">
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
          <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900318-a6913dbd" className="box vertical">
            <div className="form-block w-form">
              <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form" data-wf-page-id="64d12c15020d3a90a6913dbd" data-wf-element-id="454c95e2-b67c-98c2-4171-43e0a690031a">
                <select onChange={(e)=>setTime(e.target.value)} type="text" className="text-field w-input" maxlength="256" name="All-Time" data-name="All Time" placeholder="All Time" id="time-range">
                  <option value="">All-time</option>
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                  <option value="3months">3-Months</option>
                </select>
                <input onChange={(e)=>setUser(e.target.value)} type="text" className="text-field w-input" maxlength="256" name="Person" data-name="Person" placeholder="All Individuals" id="individual"/>
                <button onClick={(e)=>getData(e)}>Search</button>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
          <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900323-a6913dbd" className="box no-padding">
            <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900324-a6913dbd" className="top-box-shadow">
              <div>
                <div className="h2 left-mobile">Recent Sales:</div>
              </div>
              <div className="data-grid right-padding">
                <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900329-a6913dbd" className="grey">
                  <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a690032a-a6913dbd">Name</div>
                </div>
                <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a690032c-a6913dbd" className="grey">
                  <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a690032d-a6913dbd">Date</div>
                </div>
                <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a690032f-a6913dbd" className="grey">
                  <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900330-a6913dbd">Method</div>
                </div>
                <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900332-a6913dbd" className="grey">
                  <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900333-a6913dbd">USDC</div>
                </div>
                <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900335-a6913dbd" className="grey">
                  <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900336-a6913dbd">$TMPST</div>
                </div>
              </div>
            </div>
            <div className="data-contained">
            { spin ? <Spin><div className="content" style={{margin:"20px", zIndex:'100'}} /></Spin> : Data}
            </div>
          </div>
          <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a6900419-a6913dbd" className="box vertical">
            <div className="h2">Profile:</div>
            <div className="two-grid _30-margin-top">
              <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a690041d-a6913dbd" className="individuals-container">
                <div id="person-name" className="_18px">All Individuals</div>
                <div className="stripe-vs-wallet">
                  <div className="flex-horizontal">
                    <div className="grey _5-margin-right">Stripe:</div>
                    <div id="stripe-percentage" className="grey">43%</div>
                  </div>
                  <div className="flex-horizontal">
                    <div className="grey _5-margin-right">Wallet</div>
                    <div id="wallet-percentage" className="grey">57%</div>
                  </div>
                </div>
                <div className="right-divider"></div>
              </div>
              <div id="w-node-_454c95e2-b67c-98c2-4171-43e0a690042b-a6913dbd">
                <div className="grey">Tokens purchased</div>
                <div className="light-blue-container _10-margin-top"><img src="images/coin-small_1coin-small.png" loading="lazy" alt="" className="coin-icon"/>
                  <div id="tokens-amount" className="h3">{totaltokens.toFixed(2)}</div>
                </div>
                <div className="flex-horizontal blue-text">
                  <div>= </div>
                  <div id="usdc-amount">${totalUsd}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="w-node-_89ac0e93-3e95-bc7e-46d6-c79ba60599d9-a6913dbd" class="divider-line _100"></div>
        <div class="grid two-landscape">
        {teams}
          
          {leader}
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default Dash;
