import React from "react";
import { useState } from "react";
import {useNavigate} from 'react-router-dom'
import {Spin} from 'antd'

const EmployeeRegister = (props) =>{
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [spin,setSpin] = useState(false)

    async function register(e){
      e.preventDefault()
      const body = {email,password,name}
      setSpin(true)
      try {
         let res = await fetch('https://tempestapi.onrender.com/employee-register',{
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body:JSON.stringify(body)
         }) 

         if(res.ok){
          res = await res.json()
          localStorage.setItem("jwt",res.token)
          localStorage.setItem("loggedIn",'true')
          localStorage.setItem('admin',`${res.admin}`)
          props.setLoggedIn(true) 
          setSpin(false)
          return  navigate('/')
         }
         
      } catch (error) {
        setSpin(false)
        alert("Email or password incorrect")
        console.log(error)
      }
  }


return(

    <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f0f0f0',
    }}
  >
    <div
      style={{
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <h2>Admin Register</h2>
      <form>
        <div style={{ marginBottom: '20px' }}>
          <label>Name:</label>
          <input onChange={(e)=>setName(e.target.value)} type="text" style={{ width: '100%' }} />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>Email:</label>
          <input onChange={(e)=>setEmail(e.target.value)} type="email" style={{ width: '100%' }} />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label>Password:</label>
          <input onChange={(e)=>setPassword(e.target.value)} type="password" style={{ width: '100%' }} />
        </div>
        { spin ? <Spin><div className="content" style={{margin:"20px", zIndex:'100'}} /></Spin> :<button
          type="submit"
          style={{
            backgroundColor: '#007bff',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}

          onClick={(e)=>register(e)}
        >
          Register
        </button>}
      </form>
    </div>
  </div>

)
}

export default EmployeeRegister