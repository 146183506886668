import React from "react";
import { useState, useEffect } from "react";
import Dash from "./components/admindash";
import AdminLogin from './components/admin-login'
import AdminRegister from "./components/admin-register"
import EmployeeDash from "./components/employee-dash";
import EmployeeRegister from "./components/employee-register"
import { Routes, Route } from "react-router-dom";
function App() {

  const [loggedIn, setLoggedIn] = useState(false)
  
  const [admin, setAdmin] = useState('')

  async function checkLoggedIn(){
    if(localStorage.getItem('loggedIn')){
        setLoggedIn(true)
    }else setLoggedIn(false)
  }  
  

  useEffect(()=>{
    checkLoggedIn()
  },[loggedIn])
  
  return (
    <Routes>
        <Route path="/" element={loggedIn?<Dash setLoggedIn={setLoggedIn} /> : <AdminLogin  setLoggedIn={setLoggedIn} />}/>
        <Route path="/admin-register" element={< AdminRegister  setLoggedIn={setLoggedIn} />}/>
        <Route path="/employee-dash" element={loggedIn&& <EmployeeDash setLoggedIn={setLoggedIn}/>}/>
        <Route path="/employee-register" element={< EmployeeRegister  setLoggedIn={setLoggedIn} />}/>
    </Routes>
  );
}

export default App;
